import {
  editorConstants,
  CURRENT_USER_EXTRACTED_PROPERTIES,
} from "./constants";
import axios from "axios";
import store from "../store/store";
import router from "../router/index.js";
import _ from "lodash";

const singleDigitFormat = (val) => `${Math.floor(val)}`.slice(-2);
const doubleDigitFormat = (val) => `0${Math.floor(val)}`.slice(-2);

/* eslint-disable */
export function checkToken(savedToken) {
  const token = JSON.parse(savedToken);
  if (Date.parse(token.accessTokenExpiresAt) < Date.now()) {
    console.log("Access token expired", token.accessTokenExpiresAt);
    if (Date.parse(token.refreshTokenExpiresAt) < Date.now()) {
      // TODO: implement logout
      logout(router);
      return;
    } else {
      // auth data
      const data = { refreshToken: token.refreshToken };
      axios
        .post(process.env.VUE_APP_MP_SERVER_API + "/auth/refresh", data, {
          withCredentials: false,
        })
        .then((response) => {
          if (response.data.status !== "success") {
            console.error("error refreshing token", response.data.message);
            logout(router);

            return;
          } else {
            console.error("Success refreshing", response.data.data);
            localStorage.setItem("tokenMP", JSON.stringify(response.data.data));
            return true;
          }
        })
        .catch((error) => {
          // TODO: implement logout
          console.log("logout", error);
          logout(router);
        });
    }
  } else {
    return true;
  }
}

/**
 *
 * @returns A boolean representing if the user details were successfully refreshed
 */
export async function refreshCurrentUser() {
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(
    process.env.VUE_APP_MP_SERVER_API_URL + "self/",
    requestOptions
  );
  if (response.ok) {
    const data = await response.json();

    store.commit("updateCurrentUser", {
      ..._.pick(data.data, CURRENT_USER_EXTRACTED_PROPERTIES),
    });
    return true;
  } else {
    // Server returned error, push logout
    logout(router);
    return false;
  }
}

export function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function logout(router) {
  console.log("logging out", router);
  localStorage.removeItem("tokenMP");
  router.push("/");
}

export function convertDateToMonthDayYear(timestamp) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return timestamp.toLocaleDateString("en-US", options);
}

//Convert from seconds to hour:min:sec display
export function convertSecondsToHMS(seconds) {
  var hours = seconds / 3600;
  var minutes = (seconds % 3600) / 60;
  let minutesSeconds = [minutes, seconds % 60].map(doubleDigitFormat).join(":");
  // let hours = [hours].map(formatHours).join(":");
  return [[hours].map(singleDigitFormat), minutesSeconds].join(":");
}

//Convert from milliseconds to hour:min:sec display
export function convertMillisecondsToHMS(ms) {
  let seconds = Math.floor((ms / 1000) % 60),
    minutes = Math.floor((ms / (1000 * 60)) % 60),
    hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
  let minutesSeconds = [minutes, seconds % 60].map(doubleDigitFormat).join(":");
  return [[hours].map(doubleDigitFormat), minutesSeconds].join(":");
}

//Convert from seconds to min:sec display
export function convertSecondsToMS(seconds) {
  var minutes = (seconds % 3600) / 60;
  return [minutes, seconds % 60].map(doubleDigitFormat).join(":");
}

// Convert the "times" data field of the Stopwatch.vue component to MS
export function convertTimetoMilliseconds(time) {
  let hrs = time[0];
  let min = time[1];
  let sec = time[2];
  let ms = time[3];
  return Math.round((hrs * 60 * 60 + min * 60 + sec) * 1000 + ms);
}

//turn 128 seconds into 2:08
export function getTimeCodeFromNum(num) {
  let seconds = parseInt(num);
  let minutes = parseInt(seconds / 60);
  seconds -= minutes * 60;
  const hours = parseInt(minutes / 60);
  minutes -= hours * 60;

  if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
  return `${String(hours).padStart(2, 0)}:${minutes}:${String(
    seconds % 60
  ).padStart(2, 0)}`;
}

// Convert to only first letter of each word capitalized
export function toTitleCase(str) {
  return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
}

export function convertDateToHoursMinutes(date) {
  return (
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes()
  );
}

export function getRandomElement(list) {
  return list[Math.floor(Math.random() * list.length)];
}

export function getRandomColor() {
  return getRandomElement([
    "#958DF1",
    "#F98181",
    "#FBBC88",
    "#FAF594",
    "#70CFF8",
    "#94FADB",
    "#B9F18D",
  ]);
}

export function convertHTMLToString(content) {
  // Any closing html tag followed by a new paragraph tag
  content = content.replaceAll(
    /<\/[^<>]*><p>/g,
    editorConstants.NEWLINE_CHAR_P
  );
  content = content.replaceAll("<br>", editorConstants.NEWLINE_CHAR_BR);
  content = content.replaceAll("<p>", "");
  content = content.replaceAll("</p>", "");
  content = content.replace(/(<([^>]+)>)/gi, "");
  return content;
}

export function unescapeCharacters(content) {
  // From what I've seen so far, ProseMirror only escapes the characters: & <>
  content = content.replaceAll("&amp;", "&");
  content = content.replaceAll("&lt;", "<");
  content = content.replaceAll("&gt;", ">");
  content = content.replaceAll("&nbsp;", " ");
  return content;
}

export function scrollToBottom(el) {
  el.scrollTop = el.scrollHeight;
}

export function getArrayWithLimitedLength(length) {
  let array = new Array();

  array.unshift = function () {
    if (this.length >= length) {
      this.pop();
    }
    return Array.prototype.unshift.apply(this, arguments);
  };

  return array;
}

export function capitalizeFirstLetter(string) {
  const firstWordFirstChar = string.match(/^\W*([\w-])/);
  if (firstWordFirstChar) {
    const lastMatchIndex = firstWordFirstChar.length - 1;
    return string.replace(firstWordFirstChar[lastMatchIndex], (c) =>
      _.upperFirst(c)
    );
  }
}

export function generateUID() {
  return Math.random().toString().slice(2, 11);
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

/** Paste richly formatted text.
 *
 * @param {string} rich - the text formatted as HTML
 * @param {string} plain - a plain text fallback
 */
export async function copyFormattedHtml(rich, plain) {
  if (typeof ClipboardItem !== "undefined") {
    // Shiny new Clipboard API, not fully supported in Firefox.
    // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#browser_compatibility
    const html = new Blob([rich], { type: "text/html" });
    const text = new Blob([plain], { type: "text/plain" });
    const data = new ClipboardItem({ "text/html": html, "text/plain": text });
    await navigator.clipboard.write([data]);
  } else {
    // Fallback using the deprecated `document.execCommand`.
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
    const cb = (e) => {
      e.clipboardData.setData("text/html", rich);
      e.clipboardData.setData("text/plain", plain);
      e.preventDefault();
    };
    document.addEventListener("copy", cb);
    document.execCommand("copy");
    document.removeEventListener("copy", cb);
  }
}

/**
 * Converts string containing ordered list to equivalent HTML string
 * @param {string} listString
 */
export function convertOrderedListStringToHtml(listString) {
  const listRegex = /([\d]+\. )(.*?)(?=([\d]+\.)|($))/gs;
  const bulletRegex = /^[\d]+\. /gms;
  const listItems = listString.match(listRegex);
  let convertedHtmlString = listString;
  for (let i = 0; i < listItems.length; i++) {
    const firstLoop = i === 0;
    const lastLoop = i === listItems.length - 1;

    let strippedBulletListItem = listItems[i].replace(bulletRegex, "");

    // Wrap list with ordered list tags and list items with list item tags
    convertedHtmlString = convertedHtmlString.replace(
      listItems[i],
      (firstLoop ? "<ol>" : "") +
        "<li>" +
        strippedBulletListItem +
        "</li>" +
        (lastLoop ? "</ol>" : "")
    );
  }
  return convertedHtmlString;
}

export async function getCurrentUserSubscriptionDetails() {
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(
    process.env.VUE_APP_MP_SERVER_API_URL + "self/",
    requestOptions
  );
  if (response.ok) {
    const data = await response.json();

    const subscriptionDetails = data.data.subscription;
    const subscriptionStatus = subscriptionDetails?.status;

    return {
      isActive:
        subscriptionStatus?.includes("active") ||
        subscriptionStatus?.includes("trialing"),
      isCancelling: subscriptionStatus?.includes("cancelling"),
      isTrial: subscriptionStatus?.includes("trialing"),
      isStripe: subscriptionDetails?.provider === "stripe",
      isAppStore: subscriptionDetails?.provider === "app-store",
      isBasic: subscriptionDetails?.type === "basic",
      isPro: subscriptionDetails?.type === "pro",
      isFreeTrialEligible: data.data.stripeSubscriptionFreeTrialEligible,
    };
  } else {
    // Server returned error
    console.error("Error retrieving subscription info");
    return {};
  }
}

/**
 * Artifical delay, useful for chaining timeouts
 * @param {number} duration - duration of the delay in ms
 */
export function delay(duration) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
}

/**
 * Formatting date - originally from StandbySessions to format date
 */
export function formatDate(
  date,
  time1,
  time2,
  isBookingDateTime = false,
  checkToday = false
) {
  // setting the timezone to London only when displaying booking time/date
  // for captioners
  let d = date
    ? new Date(date).toLocaleDateString("en-CA", {
        ...(isBookingDateTime ? { timeZone: "Europe/London" } : {}),
      })
    : "";
  let t1 = time1
    ? new Date(time1).toLocaleTimeString("en-US", {
        timeStyle: "short",
        ...(isBookingDateTime ? { timeZone: "Europe/London" } : {}),
      })
    : "";
  let t2 = time2
    ? new Date(time2).toLocaleTimeString("en-US", {
        timeStyle: "short",
        ...(isBookingDateTime ? { timeZone: "Europe/London" } : {}),
      })
    : "";
  if (!d && !t1 && !t2) {
    return "No Date!";
  } else if (checkToday && d === new Date().toLocaleDateString("en-CA")) {
    return t1.concat(t2 ? " - " : "", t2, " Today");
  } else {
    return d.concat(", ", t1, t2 ? " - " : "", t2);
  }
}
/* eslint-enable */
