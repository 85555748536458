<template>
  <div class="panels-wrapper">
    <InfoPanel
      :isOpen="infoPanelOpen"
      :toggleInfoPanelOpen="toggleInfoPanelOpen"
      sessionBrowserPage
      class="info-panel"
      :openNewSessionModal="setNewSessionModalVisible"
    />
    <div
      class="recorder-content content-panel"
      :class="showPersonalNotes ? 'notes-open' : 'notes-closed'"
    >
      <div class="top" :style="showSaveScreen ? { display: 'none' } : {}">
        <button class="top-record-button" @click="RECORD">
          <div v-if="!recording" class="top-record-button-inner" />
          <img v-else :src="PauseIcon" alt="Pause Icon" />
        </button>
        <div class="top-stopwatch">
          <IconButton
            v-if="!recording"
            :icon="PauseIcon"
            alt="Pause"
            class="top-stopwatch-icon"
          />
          <div v-else class="top-stopwatch-icon record-red-dot" />
          <Stopwatch
            @max-time-reached="maxTimeReached"
            @display-hours="displayHoursLabel"
            ref="stopwatch"
          />
        </div>
        <div class="top-record-icons">
          <IconButton
            :icon="CheckmarkIcon"
            alt="Checkmark"
            xsmall
            class="top-icon"
            @click="showSaveDialog()"
          />
          <IconButton
            @click="showDeleteDialog()"
            :icon="TrashIcon"
            alt="Trash"
            xsmall
            class="top-icon"
          />
          <div class="top-vertical" />
          <IconButton
            @click="addBookmarkTimestamp()"
            :icon="BookmarkIcon"
            alt="Bookmark"
            xsmall
            class="top-icon"
          />
        </div>
      </div>
      <button
        v-if="!hideBackButton"
        class="back-button"
        @click="
          () => {
            this.showSaveScreen = false;
            hideBackButton = !hideBackButton;
          }
        "
      >
        <img :src="ChevronLeftGreyIcon" />
        <span>Back</span>
      </button>
      <div class="time-label-container"></div>
      <div
        v-if="timeLimitReached && !showSaveScreen"
        class="time-limit-message"
      >
        Recording stopped at {{ timestamp }}
      </div>
      <div v-if="!showSaveScreen" class="session-title">
        <span
          class="session-title-edit"
          role="textbox"
          contenteditable="true"
          v-text="sessionTitle"
          :placeholder="sessionTitlePlaceholder"
          @focus="focusSessionTitle"
          @blur="onEdit"
          @keydown="limitChar"
          @keydown.enter="endEdit"
        >
        </span>
        <img :src="EditIcon" alt="Edit" class="edit-icon" />
      </div>
      <Transition>
        <div
          v-if="!showSaveScreen"
          class="waveform-wrapper"
          style="width: 100%"
        >
          <div id="waveform">
            <div
              v-for="bar in BARS"
              :key="bar.id"
              :style="{ height: bar.size + '%', opacity: bar.size / 100 }"
              class="rect"
            ></div>
          </div>
        </div>
        <SaveRecordingForm
          @stop-recorder="uploadRecording"
          @update-title="updateTitle"
          :recordingTitle="recordingTitle"
          :recordingDuration="$refs.stopwatch.times"
          ref="saveRecordingForm"
          v-else
        ></SaveRecordingForm>
      </Transition>
      <div class="personal-notes-wrapper" v-if="!showSaveScreen">
        <TextFormattingBar
          :editor="personalNoteEditor"
          :zoomFactor="zoomFactorNotes"
          :setZoomFactor="setNotesZoomFactor"
          :addImage="addNotesImage"
          :showEmoji="showNotesEmoji"
          :highlightedFontSize="highlightedFontSize"
          :setHighlightedFontSize="setHighlightedFontSize"
          :class="{ 'disable-and-blur': disablePersonalNote }"
        />
        <NoteEditor
          v-if="showPersonalNotes && sessionId"
          class="personal-notes-editor"
          :id="sessionId"
          :noteEditor="personalNoteEditor"
          :setNoteEditor="setPersonalNoteEditor"
          :editable="true"
          :zoomFactor="zoomFactorNotes"
          :isPersonalNote="true"
          :disablePersonalNote="disablePersonalNote"
          :showDisablePersonalNoteDialog="disablePersonalNote"
          :setSubscribeVisible="setShowSubscriptionSignupDialog"
        />
      </div>
      <div class="bottom-bar" v-if="!showSaveScreen">
        <RoundedSquareButton
          :image="NotesPencilPurpleIcon"
          imageAlt="Toggle personal notes"
          :onClick="
            () => {
              showPersonalNotes = !showPersonalNotes;
            }
          "
          xtall
          v-tooltip.top="'Toggle personal notes'"
        />
      </div>
    </div>

    <Dialog
      v-model:visible="deleteModalVisible"
      :modal="true"
      :closable="true"
      header="Delete Recording?"
      :dismissableMask="true"
      :style="{ width: '44rem' }"
      class="dialog-wrapper"
      ><p>Are you sure you want to permanently delete this recording?</p>
      <div class="modal-buttons">
        <Button
          @click="deleteModalVisible = false"
          class="modal-button cancel-delete-button"
          label="Cancel"
        ></Button
        ><Button
          @click="confirmDelete()"
          class="modal-button confirm-delete-button"
          label="Delete"
        ></Button>
      </div>
    </Dialog>
    <Dialog
      v-model:visible="saveModalVisible"
      :modal="true"
      :closable="true"
      header="Save Recording?"
      :dismissableMask="true"
      :style="{ width: '44rem' }"
      class="dialog-wrapper"
    >
      <p>
        Once you’re ready to save and finalize your recording, simply click
        ‘Proceed,’ and you’ll be prompted to provide recording details.
      </p>
      <div class="modal-buttons">
        <Button
          @click="saveModalVisible = false"
          class="modal-button cancel-delete-button"
          label="Cancel"
        ></Button
        ><Button
          @click="confirmSave()"
          class="modal-button confirm-proceed-button"
          label="Proceed"
        ></Button></div
    ></Dialog>
    <!-- Master Wrapper -->
    <Toast
      position="bottom-center"
      :style="{ marginBottom: '10rem' }"
      group="bc"
      :closeButton="null"
    >
      <template #message="slotProps">
        <img
          v-if="slotProps.message.summary.includes('Bookmark')"
          :src="BookmarkFilled"
          alt="BookmarkFilled"
        />
        <div>
          {{ slotProps.message.summary }}
        </div>
      </template>
    </Toast>
  </div>
  <RequestNotesModal
    :sessionTitle="recordingTitle"
    :sessionDurationHMS="$refs.stopwatch?.time.toString()"
    :sessionDurationMS="recordingDuration.toString()"
    :uploadId="uploadId"
    :visible="showRequestNotesModal"
    :setVisible="setShowRequestNotesModal"
    @goHome="goHome()"
  />
  <NewSessionModal
    :visible="newSessionModalVisible"
    :setVisible="setNewSessionModalVisible"
  />
  <SubscriptionSignup
    :visible="showSubscriptionSignupDialog"
    :setVisible="setShowSubscriptionSignupDialog"
    @subscriptionSuccess="userJustSubscribed"
  />
</template>
<script>
// import { dbConstants } from "../assets/constants";
import { NOTES_PANEL_DEFAULTS } from "../assets/constants";
import {
  logout,
  convertTimetoMilliseconds,
  getCurrentUserSubscriptionDetails,
} from "../assets/helpers_legacy";
import { limitCharacter } from "../assets/helpers";
import InfoPanel from "../components/InfoPanel";
import ChevronRightPurpleIcon from "../assets/images/Chevron-right-purple.svg";
import ChevronLeftGreyIcon from "../assets/images/Chevron-left-grey.svg";
import Stopwatch from "../components/Stopwatch.vue";
import SaveRecordingForm from "../components/SaveRecordingForm.vue";
import RequestNotesModal from "../components/RequestNotesModal.vue";
import gsap from "gsap";
import { reactive } from "vue";
import Pause from "../assets/images/Pause-black.svg";
import PauseRed from "../assets/images/Pause-red.svg";
import AvertaBold from "../assets/fonts/Averta-Bold.otf";
import IconButton from "../components/IconButton.vue";
import CheckmarkIcon from "../assets/images/Checkmark-white.svg";
import TrashIcon from "../assets/images/Trash-white.svg";
import BookmarkIcon from "../assets/images/Bookmark-white.svg";
import EditIcon from "../assets/images/EditIcon.svg";
import Toast from "primevue/toast";
import PauseIcon from "../assets/images/Pause-white2.svg";
import BookmarkFilled from "../assets/images/Bookmark-filled.svg";
import NotesPencilPurpleIcon from "../assets/images/Notes-pencil-purple.svg";
import NewSessionModal from "../components/NewSessionModal.vue";
import RoundedSquareButton from "../components/RoundedSquareButton.vue";
import NoteEditor from "../components/NoteTaking/NoteEditor.vue";
import TextFormattingBar from "../components/NoteTaking/TextFormattingBar.vue";
import SubscriptionSignup from "../components/Onboarding/SubscriptionSignup.vue";

export default {
  components: {
    IconButton,
    InfoPanel,
    Stopwatch,
    SaveRecordingForm,
    Toast,
    RequestNotesModal,
    NewSessionModal,
    NoteEditor,
    RoundedSquareButton,
    TextFormattingBar,
    SubscriptionSignup,
  },

  data() {
    return {
      BookmarkIcon,
      CheckmarkIcon,
      ChevronRightPurpleIcon,
      ChevronLeftGreyIcon,
      EditIcon,
      Pause,
      PauseRed,
      AvertaBold,
      TrashIcon,
      PauseIcon,
      NotesPencilPurpleIcon,
      BookmarkFilled,
      infoPanelOpen: false,

      recorder: null,
      visualizing: false,
      gsap: null,
      recording: false,
      paused: false,
      timeLimitReached: false,
      hours: false,

      AUDIO_CONTEXT: null,
      ANALYSER: null,
      BARS: [],
      DATA_ARR: null,
      CHUNKS: [],
      BLOB: null,

      deleteModalVisible: false,
      saveModalVisible: false,
      resumeButtonDisabled: false,
      prompt: "Click the Record button to start recording.",
      showSaveScreen: false,
      hideBackButton: true,
      recordingTitle: "",
      recordingDuration: 0,

      sessionId: null,
      uploadId: "",
      multipartUploadURL: null,

      CONFIG: {
        fft: 2048,
        show: false,
        duration: 0.1,
        fps: 30,
        barWidth: 2,
        barMinHeight: 0.0,
        barMaxHeight: 1,
        barGap: 1,
      },
      barArrayMax: null,
      waveform: null,
      count: 0,
      winSize: null,

      sessionTitle: "",
      showRequestNotesModal: false,

      bookmarkTimestamps: [],
      sessionTitlePlaceholder: "Enter session title",
      canShowToast: true,
      newSessionModalVisible: false,

      /* Note editor variables */
      showPersonalNotes: false,
      personalNoteEditor: null,
      zoomFactorNotes: 1,
      highlightedFontSize: NOTES_PANEL_DEFAULTS.FONT_SIZE,
      currentUserSubscriptionDetails: {},
      showSubscriptionSignupDialog: false,
    };
  },

  computed: {
    disablePersonalNote() {
      return (
        !this.currentUserSubscriptionDetails.isActive && !this.isServiceProvider
      );
    },

    isServiceProvider() {
      switch (this.$store.state.currentUser.role.toLowerCase()) {
        case "worker":
        case "service_provider":
          return true;
        default:
          return false;
      }
    },
  },

  async created() {
    /**
     * Generate the UUID to set as the session id
     * We need to generate this because our Tiptap editor is collaborative
     * and relies on the sessionId so we can't just wait for the server to
     * assign an id once uploaded
     */
    this.sessionId = crypto.randomUUID();

    this.currentUserSubscriptionDetails =
      await getCurrentUserSubscriptionDetails();
  },

  mounted() {
    this.winSize = window.innerWidth;
    this.BARS = reactive([]);
    this.waveform = document.getElementById("waveform");
    this.barArrayMax = document.getElementById("waveform").offsetWidth / 8;

    this.populateWaveform();
  },

  methods: {
    /** Note editor methods **/

    setPersonalNoteEditor(personalNoteEditor) {
      this.personalNoteEditor = personalNoteEditor;
      this.personalNoteEditor.on("selectionUpdate", ({ editor }) => {
        this.highlightedFontSize = editor.getAttributes("textStyle").fontSize;
      });
    },

    setNotesZoomFactor(zoomFactor) {
      this.zoomFactorNotes = zoomFactor;
    },

    addNotesImage() {
      const url = window.prompt("URL");

      if (url) {
        this.personalNoteEditor.chain().focus().setImage({ src: url }).run();
      }
    },

    showNotesEmoji(emoji) {
      this.personalNoteEditor.chain().focus().setEmoji(emoji.id).run();
    },

    setHighlightedFontSize(fontSize) {
      this.highlightedFontSize = fontSize;
    },

    setShowSubscriptionSignupDialog(bool) {
      this.showSubscriptionSignupDialog = bool;
    },

    /**
     * Manually set subscription tag to active to allow user to access paid features
     * because it takes some time for the change to take effect on the server-side
     **/
    userJustSubscribed() {
      this.currentUserSubscriptionDetails.isActive = true;
    },

    /** End of note editor methods **/

    onToastClose() {
      this.canShowToast = true;
    },

    showToast(msg) {
      if (this.canShowToast) {
        this.canShowToast = false;
        this.$toast.add({
          summary: msg,
          life: 3000,
          group: "bc",
        });
        window.setTimeout(this.onToastClose, 3500);
      }
    },

    addBookmarkTimestamp() {
      const currentTime = convertTimetoMilliseconds(this.$refs.stopwatch.times);

      if (
        currentTime ===
        this.bookmarkTimestamps[this.bookmarkTimestamps.length - 1]
      ) {
        // ignore bookmark in the same spot
        return;
      }

      this.bookmarkTimestamps.push(currentTime);
      this.$toast.add({
        summary: `${this.$refs.stopwatch.time} Bookmarked!`,
        life: 3000,
        group: "bc",
      });
    },

    onEdit(evt) {
      this.sessionTitle = evt.target.innerText;
      this.sessionTitlePlaceholder = "Enter session title";
    },

    endEdit() {
      this.$el.querySelector("span.session-title-edit").blur();
    },

    limitChar(event) {
      limitCharacter(event, event.target.innerText, this.showToast);
    },
    /* Edit title during recording feature -- end */

    logout() {
      logout(this.$router);
    },

    toggleInfoPanelOpen() {
      this.infoPanelOpen = !this.infoPanelOpen;
    },

    ANALYSE(stream) {
      this.AUDIO_CONTEXT = new AudioContext();
      this.ANALYSER = this.AUDIO_CONTEXT.createAnalyser();
      this.ANALYSER.fftSize = this.CONFIG.fft;
      const SOURCE = this.AUDIO_CONTEXT.createMediaStreamSource(stream);
      this.DATA_ARR = new Uint8Array(this.ANALYSER.frequencyBinCount);
      this.ANALYSER.smoothingTimeConstant = 0.6;
      this.ANALYSER.minDecibels = -80;
      this.ANALYSER.maxDecibels = -10;
      SOURCE.connect(this.ANALYSER);

      gsap.ticker.add(this.REPORT);
    },

    REPORT() {
      if (this.recorder) {
        this.ANALYSER.getByteFrequencyData(this.DATA_ARR);
        let VOLUME = Math.floor((Math.max(...this.DATA_ARR) / 255) * 100);
        this.count++;
        if (VOLUME < 5) {
          VOLUME = 5;
        }
        const BAR = {
          id: this.count,
          size: VOLUME,
        };
        this.BARS.push(BAR);
      }

      if (this.recorder || this.visualizing) {
        this.BARS.shift();
      }
    },

    RECORD() {
      this.timeline = gsap.timeline();

      gsap.ticker.fps(this.CONFIG.fps);

      const toggleRecording = async () => {
        if (!this.recorder) {
          this.recording = true;
          this.paused = false;
          this.visualizing = true;

          const MEDIA_STREAM = await window.navigator.mediaDevices.getUserMedia(
            {
              audio: true,
            }
          );

          let options = {};
          if (MediaRecorder.isTypeSupported("audio/webm")) {
            options = { mimeType: "audio/webm", audioBitsPerSecond: 128000 };
          } else if (MediaRecorder.isTypeSupported("video/mp4")) {
            options = { mimeType: "video/mp4", videoBitsPerSecond: 100000 };
          } else {
            console.error("No suitable mimetype found for this device");
          }

          this.recorder = new MediaRecorder(MEDIA_STREAM, options);
          this.recorder.ondataavailable = (event) => {
            this.CHUNKS.push(event.data);
          };
          this.recorder.onstop = () => {
            this.BLOB = new Blob(this.CHUNKS, {
              type: "audio/webm; codecs=opus",
            });
          };
          this.$refs.stopwatch.start();
          this.recorder.start();
          this.ANALYSE(this.recorder.stream);
        } else if (this.recorder && !this.paused) {
          this.visualizing = false;
          this.recording = false;
          this.paused = true;
          // Stop the recorder and return the nodes back to where they were
          this.$refs.stopwatch.stop();
          this.recorder.pause();
          this.timeline.clear();
          gsap.ticker.remove(this.REPORT);
          // this.AUDIO_CONTEXT.close();
        } else if (this.recorder && this.paused) {
          this.recording = true;
          this.visualizing = true;
          this.recorder.resume();
          this.paused = false;
          this.$refs.stopwatch.start();
          this.ANALYSE(this.recorder.stream);
        }
      };
      toggleRecording();
    },

    populateWaveform() {
      this.BARS = [];
      this.count = 0;
      for (let i = 0; i < this.barArrayMax; i++) {
        let BAR = {
          id: this.count,
          size: 4,
        };
        // Add to bars Array
        this.BARS.push(BAR);
        this.count++;
      }
    },

    showDeleteDialog() {
      this.deleteModalVisible = true;
    },

    showSaveDialog() {
      this.saveModalVisible = true;
    },

    confirmDelete() {
      this.sessionTitle = "";
      this.timeLimitReached = false;
      this.hours = false;
      this.recorder = null;
      this.paused = false;
      this.recording = false;
      this.visualizing = false;
      this.$refs.stopwatch.reset();
      this.populateWaveform();
      this.deleteModalVisible = false;
    },

    confirmSave() {
      this.createTimeFileName();
      // pause audio when the user confirms
      if (this.recording) {
        this.RECORD();
      }
      this.hideBackButton = false;
      this.saveModalVisible = false;
      this.showSaveScreen = true;
    },

    maxTimeReached(time) {
      this.timestamp = this.getTime(time);
      this.timeLimitReached = true;
      this.prompt = "Oops! The maximum length of 3 hours was reached.";
      this.paused = true;
      this.recording = false;
      this.visualizing = false;
      this.recorder.stop();
      this.resumeButtonDisabled = true;
    },
    getTime(time) {
      const dt = new Date(time);
      let hours = dt.getHours();
      const meridiemSymbol = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      let minutes = dt.getMinutes();
      minutes = (minutes < 10 ? "0" : "") + minutes;
      let seconds = dt.getSeconds();
      seconds = (seconds < 10 ? "0" : "") + seconds;
      let finalTime =
        hours + ":" + minutes + ":" + seconds + " " + meridiemSymbol;
      return finalTime;
    },

    createTimeFileName() {
      if (this.sessionTitle === "") {
        this.recordingTitle = "New session";
      } else {
        this.recordingTitle = this.sessionTitle;
      }
    },

    displayHoursLabel() {
      this.hours = true;
    },

    convertTimetoMilliseconds(time) {
      let hrs = time[0];
      let min = time[1];
      let sec = time[2];
      let ms = time[3];
      return Math.round((hrs * 60 * 60 + min * 60 + sec) * 1000 + ms);
    },

    // create a session record in mp-server, retrieve sessionId (this.sessionId)
    async createSession() {
      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        title: this.recordingTitle,
        localSessionId: this.sessionId,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL + "self/sessions",
        requestOptions
      );
      const data = await response.json();
      return data.data.id;
    },

    // create an upload record in mp-server to retrieve uploadId to set up multipart upload URL in S3
    async createUpload(sessionId) {
      this.recordingDuration = this.convertTimetoMilliseconds(
        this.$refs.stopwatch.times
      );
      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        sessionId: sessionId,
        fileExtension: "webm",
        type: "mp-desktop-recording",
        mimeType: "audio/webm",
        duration: this.recordingDuration,
        multipart: true,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL + "self/uploads",
        requestOptions
      );

      const data = await response.json();
      return data.data.id;
    },

    // create URL for an S3 multipart upload, we use this to PUT BLOB to S3
    async createMultipartUploadURL(uploadId) {
      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        partNumber: 1,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL +
          `self/uploads/${uploadId}/create-multipart-upload-url`,
        requestOptions
      );
      const data = await response.json();
      return data.data;
    },

    async uploadToS3(signedURL) {
      const response = await fetch(signedURL, {
        method: "PUT",
        body: this.BLOB,
        headers: new Headers({
          "Content-Type": "audio/webm",
        }),
      });
      let etag = response.headers.get("ETag");

      let parts = [
        {
          partNumber: 1,
          eTag: etag.replace(/['"]+/g, ""),
        },
      ];
      return parts;
    },

    async completeMultipartUpload(uploadId, parts) {
      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        parts,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.VUE_APP_MP_SERVER_API_URL +
          `self/uploads/${uploadId}/complete-multipart-upload`,
        requestOptions
      )
        .then((response) => response.text())
        .catch((error) => console.log("error", error));
    },

    async uploadRecording() {
      // stop recorder to trigger MediaRecorder 'onstop' event. This will wrap up the captured audio into a Blob
      this.recorder.stop();

      /* create in mp-server:
        -session record
        -upload record
        -multipart upload URL

        Then PUT BLOB to S3
      */
      const sessionId = await this.createSession();
      this.uploadId = await this.createUpload(sessionId);
      const multipartUploadURL = await this.createMultipartUploadURL(
        this.uploadId
      );
      const parts = await this.uploadToS3(multipartUploadURL);
      const completeUpload = await this.completeMultipartUpload(
        this.uploadId,
        parts
      ).then(() => {
        localStorage.setItem("latestRecordingUploadId", this.uploadId);
        localStorage.setItem(
          "latestRecordingTitle",
          this.$refs.saveRecordingForm.title
        );
        localStorage.setItem(
          "latestRecordingDurationMS",
          this.recordingDuration
        );
        localStorage.setItem(
          "latestRecordingDurationHMS",
          this.$refs.stopwatch.time
        );
      });
      let myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
      );
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        timestamps: this.bookmarkTimestamps,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        process.env.VUE_APP_MP_SERVER_API_URL +
          `self/uploads/${this.uploadId}/` +
          `bookmarks/batch`,
        requestOptions
      );
      const bookmarkResponse = await response.json();
      if (bookmarkResponse.status !== "success") {
        console.error(bookmarkResponse);
      }

      console.log(completeUpload);
      this.showRequestNotesModal = true;
    },

    setShowRequestNotesModal(visible) {
      this.showRequestNotesModal = visible;
    },

    updateTitle(title) {
      this.recordingTitle = title;
    },

    goHome() {
      this.$router.push("Standby");
    },

    focusSessionTitle() {
      this.sessionTitlePlaceholder = "";
    },

    setNewSessionModalVisible(visible) {
      this.newSessionModalVisible = visible;
    },
  },
};
</script>

<style lang="scss">
.info-panel {
  z-index: 1;
}

.recorder-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  font-family: $font-secondary;

  &.notes-open {
    .top {
      order: 1;
    }

    .waveform-wrapper {
      order: 2;

      #waveform {
        height: 4rem;
      }
    }

    .session-title {
      order: 3;
      margin: 0;
      padding: 0 1.6rem;
      height: 3.2rem;
      align-self: flex-start;
      margin: 0.8rem 0;

      .session-title-edit {
        font-size: 2.4rem;
      }

      .edit-icon {
        height: 2rem;
        width: 2rem;
        margin-left: 1.2rem;
      }
    }

    .personal-notes-wrapper {
      order: 4;
      flex: 1;
    }

    .bottom-bar {
      order: 5;
    }
  }

  &.notes-closed {
    .top {
      order: 1;
    }

    .waveform-wrapper {
      order: 3;
      flex: 1;

      #waveform {
        height: 10rem;
      }
    }

    .session-title {
      order: 2;
    }

    .personal-notes-wrapper {
      order: 4;
      display: none;
    }

    .bottom-bar {
      order: 5;
    }
  }

  // new bar
  .top {
    // height: 6.5rem;
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.3);
    padding: 0;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1.2rem;

    .top-record-button {
      cursor: pointer;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin-right: 1.6rem;

      .top-record-button-inner {
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        background-color: #b00020;
        z-index: 9999;
      }
    }

    .top-record-button:hover {
      .top-record-button-inner {
        background-color: #890019;
      }
    }

    .top-stopwatch {
      font-family: "Averta-Bold-Tabular-Figures";
      font-size: 1.2rem;
      margin: 0;
      padding: 0;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.8rem;
      background-color: map-get($colours, "accent-purple-3");
      width: 9.5rem;
      height: 4rem;

      .top-stopwatch-icon {
        margin-right: 0.3rem;
        margin-top: -0.1rem;

        img {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }

    .top-record-icons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .top-icon {
      margin: 0.8rem;
    }

    .top-vertical {
      height: 2.4rem;
      width: 1.6rem;
      margin-left: 1.6rem;
      border-left-color: rgba(25, 25, 25, 0.3);
      border-left-width: 0.1rem;
      border-left-style: solid;
    }
  }

  .session-title {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 4.8rem;

    .session-title-input {
      font-family: $font-default;
      white-space: nowrap;
      max-width: 88.8rem;
      overflow: visible;
      border-color: yellow;
      background: none;
      outline: none;
      font-size: 3.4rem;
      color: white;

      &::placeholder {
        color: map-get($colours, translucent-white-40);
      }

      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }

      &:select {
        border: none;
        outline: none;
      }
    }

    .session-title-edit {
      font-family: $font-default;
      font-weight: bold;
      height: 100%;
      max-width: 88.8rem;
      display: inline-block;
      text-align: center;
      border-color: yellow;
      background: none;
      outline: none;
      font-size: 3.4rem;
      color: white;
      margin-top: 0.5rem;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 2rem;

      // &::placeholder {
      //   color: white;
      // }

      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }

      &:select {
        border: none;
        outline: none;
      }

      &:empty:before {
        content: attr(placeholder);
        pointer-events: none;
        display: block;
        color: map-get($colours, translucent-white-40);
      }
    }

    .edit-icon {
      width: 2.4rem;
      height: 2.4rem;
      margin-left: 1.6rem;
      visibility: hidden;
      // display: none;
    }
  }

  [contenteditable="true"]:empty:before {
    content: attr(placeholder);
    pointer-events: none;
    display: block;
  }

  .session-title:hover {
    color: rgba(255, 255, 255, 0.8);

    .edit-icon {
      // display: block;
      visibility: visible;
    }
  }
  // end - new bar

  .back-button {
    position: absolute;
    top: 2.4rem;
    left: 1.6rem;
    display: flex;
    align-items: center;
    color: map-get($colours, grey-3);
    background: transparent;
    padding: 0;
    @include section-header-3;

    &:hover {
      text-decoration: underline;
    }
  }

  .record-status {
    display: inline-flex;
    position: absolute;
    margin-left: -4.6rem;
    font-size: 1.4rem;
    margin-top: 5vh;
  }

  .record-status-pause-icon {
    margin-right: 0.5rem;
    margin-left: -2.5rem;
  }

  .record-status-recording::after {
    display: inline-flex;
    animation: ellipsis steps(1, end) 3s infinite;
    content: "";
  }

  @keyframes ellipsis {
    0% {
      content: "";
    }

    25% {
      content: ".";
    }

    50% {
      content: "..";
    }

    75% {
      content: "...";
    }

    100% {
      content: "";
    }
  }

  .red-dot {
    margin-right: 0.75rem;
    margin-top: 0.4rem;
    background-color: #b00020;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(119, 0, 22, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(119, 0, 22, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(119, 0, 22, 0);
    }
  }

  .bounce-enter-active {
    transition-timing-function: ease-in-out;
    animation: bounce-in 0.1s;
  }

  .bounce-leave-active {
    transition-timing-function: ease-in-out;
    animation: bounce-in 0.2s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0.85);
    }

    50% {
      transform: scale(1.075);
    }

    100% {
      transform: scale(1);
    }
  }

  .v-enter-active {
    transition: opacity 0.2s ease;
  }

  .v-leave-active {
    transition: opacity 50ms ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(-20px);
    opacity: 0;
  }

  .time-label-container {
    margin-right: 1rem;

    .time-labels {
      margin-left: 4.1rem;
      display: inline-block;
      font-size: 1.4rem;
      color: #bababa;

      span {
        padding-right: 4.5rem;
      }

      span:last-of-type {
        padding-right: 0rem;
      }
    }
  }

  .time-limit-message {
    background-color: #3b2c47;
    color: #d1d1d1;
    font-size: 1.6rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
    border-radius: 2.4rem;
    margin-top: 23vh;
    position: absolute;
  }

  // .stopwatch {
  //   font-family: "Averta-Bold-Tabular-Figures";
  //   font-size: 4.8rem;
  //   margin-top: 10vh;
  //   flex-direction: row;
  //   justify-content: center;
  // }

  .rect {
    height: 10px;
    width: 4px;
    background-color: #fff;
    margin-right: 4px;
    display: inline;
    border-radius: 4px;
    transition: max-height 1s;
  }

  #waveform {
    height: 10rem;
    width: 100%;
    // margin-top: 10vh;
    // overflow: hidden;
    overflow-x: hidden;
    display: grid;
    align-items: center;
    grid-template-rows: 1fr;
    /* 2 rows */
    grid-auto-flow: column;
    vertical-align: middle;
  }

  .button-container {
    // display: inline-flex;
    margin-top: 4rem;
    // position: absolute;
  }

  .record-button {
    cursor: pointer;
    width: 6.4rem;
    height: 6.4rem;
    margin: 0 auto;
    padding: 8px;
    border-radius: 40px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 14px 0 rgba(0, 0, 0, 0.12),
      0 8px 10px 0 rgba(0, 0, 0, 0.14);
    background-color: #b00020;
    border: 7px solid #fff;
  }

  .record-button:hover {
    background-color: #890019;
  }

  .recording-pause-button {
    cursor: pointer;
    width: 6.4rem;
    height: 6.4rem;
    margin: 0 auto;
    padding: 8px;
    border-radius: 40px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 14px 0 rgba(0, 0, 0, 0.12),
      0 8px 10px 0 rgba(0, 0, 0, 0.14);
    background-color: #fff;
    border: 7px solid #fff;
    text-align: center;
  }

  .recording-pause-button:hover {
    filter: brightness(80%);
  }

  .record-button-pause-icon {
    height: 2.8rem;
    width: 2.4rem;
    margin-top: 0.35rem;
  }

  .button-text {
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
    color: #bababa;
    height: 3.5rem;

    .button-prompt-text {
      font-size: 2.4rem;
    }
  }

  .time-max-text {
    font-size: 1.4rem;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 4rem;
  }

  .p-button-icon {
    font-size: 1.8rem;
    margin-top: 0.2rem;
  }
  .p-button:hover .p-button-icon {
    font-weight: bold;
  }

  .p-button {
    background-color: #53435f;
    width: 6rem;
    height: 6rem;
    border-radius: 3rem;
    // margin-top: 1rem;
  }

  .p-button:hover {
    background-color: #685875;
  }

  .resume-button-container {
    display: inline-flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    align-items: center;
  }

  .resume-button {
    height: 6.4rem;
    width: 14.7rem;
    font-size: 1.8rem;
    font-family: "Averta";
    font-weight: bold;
    color: #b00020;
    border-radius: 3.2rem;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 14px 0 rgba(0, 0, 0, 0.12),
      0 8px 10px 0 rgba(0, 0, 0, 0.14);
    background-color: #fff;
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .resume-button:disabled {
    color: #bababa;
  }

  .delete-button {
    background-color: #b00020;
  }

  .personal-notes-wrapper {
    width: 100%;
    padding: 0 1.6rem;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;

    .personal-notes-editor {
      flex: 1;
      overflow: auto;

      .text-editor {
        padding: 0;
        margin-right: 0;
      }
    }
  }

  .bottom-bar {
    padding: 2.4rem 1.6rem;
    width: 100%;
  }
}

.dialog-wrapper {
  .p-dialog-header {
    color: white;
    font-family: "PPWoodland";
    border-top-right-radius: 1.4rem;
    border-top-left-radius: 1.4rem;
    background-color: map-get($component-colours, background-primary);

    padding: 3rem;

    display: inline-flex;
    justify-content: center;

    .p-dialog-title {
      font-size: 3.4rem;
    }

    .p-dialog-header-icon {
      margin-left: 4rem;
      margin-bottom: 2.5rem;
      position: absolute;
      color: #bababa;
    }
  }

  .p-dialog-content {
    color: white;
    border-bottom-left-radius: 1.4rem;
    border-bottom-right-radius: 1.4rem;
    background-color: map-get($component-colours, background-primary);
    font-family: "Manrope";
    text-align: center;
    padding: 0rem 4rem 4rem 4rem;
    font-size: 1.6rem;

    .modal-buttons {
      display: inline-block;
      margin-top: 4.8rem;

      .modal-button {
        height: 5.6rem;
        width: 16.8rem;
        border-radius: 0.8rem;
      }

      .cancel-delete-button {
        background-color: #d1d1d1;
        color: #474747;
      }

      .confirm-delete-button {
        margin-left: 2.4rem;
        background-color: #b00020;
      }

      .confirm-proceed-button {
        margin-left: 2.4rem;
        background-color: #877594;
      }
    }
  }
}

.record-red-dot {
  width: 12px;
  height: 12px;
  background-color: #ed4035;
  border-radius: 50%;
}
</style>
