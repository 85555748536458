import { PRO_NOTES_UNIT_PRICES, COUNTRIES_CURRENCY } from "./constants";

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
type ResponseType = 'json' | 'text';
type COUNTRY_ISO = "CA" | "GB" | "US" | "XX";

/**
 *
 * @param {string} endpoint - The endpoint to hit, to be appended to the end of the server URL
 * @param {string} method - The request method, eg. "GET", "POST", etc
 * @param {string} body - Optional, the body of the request
 * @returns - The response
 */
export async function makeMPServerRequest(endpoint: string, method: HttpMethod, responseType: ResponseType, body?: any) {
    const myHeaders = new Headers();
    myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("tokenMP")!).accessToken}`
    );

    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: method,
        headers: myHeaders,
        redirect: "follow",
        ...(body && { body: body }), //optionally include
    };

    try {
        const response = await fetch(
            process.env.VUE_APP_MP_SERVER_API_URL + endpoint,
            requestOptions
        );

        // Check if the HTTP status code indicates an error
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        try {
            if (responseType === 'text') {
                return await response.text();
            }
            else {
                return await response.json();
            }
        } catch (error: unknown) {
            // Handle parsing errors
            if (error instanceof Error) {
                throw new Error(
                    "Error parsing response from server: " + error.message
                );
            }
            else {
                console.error("An unexpected error occurred:", error);
                throw new Error("An unexpected error occurred");
            }
        }
    } catch (error) {
        // Handle network or fetching errors
        if (error instanceof Error) {
            throw new Error(
                "Network error when calling the API: " + error.message
            );
        }
        else {
            console.error("An unexpected error occurred:", error);
            throw new Error("An unexpected error occurred");
        }
    }
}

/**
 * Calculates a pro notes order in cents based on duration and user country
 * @param durationMilliseconds 
 * @param userCountryIso
 * @returns 
 */
export function calculateOrderPriceInCents(durationMilliseconds: number, userCountryISO: COUNTRY_ISO) {
    const durationMinutes = Math.ceil(durationMilliseconds / 1000 / 60);

    // Cost is calculated by the minutes, rounded up
    return (
        PRO_NOTES_UNIT_PRICES[userCountryISO]["48hours"] *
        durationMinutes
    );
}

export function getFormattedPrice(subtotal: number, userCountryISO: COUNTRY_ISO) {
    const currencySymbol = COUNTRIES_CURRENCY[userCountryISO].symbol
    const currencyAbbreviation = COUNTRIES_CURRENCY[userCountryISO].abbreviation;

    return currencySymbol + subtotal + " " + currencyAbbreviation
}

export function limitCharacter(
  event: KeyboardEvent,
  currentString: string,
  showToastMessage?: Function,
) {
  if (!event || !event.target) {
    return;
  }

  const key = event.key;
  // Ignore backspace (8) and delete (46)
  //        left (37) and right (39)
  if (
    currentString.length >= 50 &&
    key != "Backspace" &&
    key != "Delete" &&
    key != "ArrowRight" &&
    key != "ArrowLeft"
  ) {
    if (showToastMessage) {
      showToastMessage("Title can be up to 50 characters");
    }
    event.preventDefault();
  }
}